const themeGen = (theme) => {
  const res = {
    oneKrSticker: {
      rootPath: 'images/icons/',
      sticker: {
        se: '1kr_drop_sticker_nordicink.png',
        no: '0kr_drop_sticker_nordicink_NO.png',
        fi: '0e_drop_sticker_nordicink_FI.png',
        default: '1kr_drop_sticker_nordicink.png',
      },
    },
    conf: {
      showOneKronaSticker: true,
    },
  };
  return res;
};

export default themeGen;
